// src/features/ProductCatalogue/components/Filters.jsx
import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Card } from 'react-bootstrap';
import './Filters.css'; // Import the custom CSS

const Filters = ({ categories, onFilterChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    onFilterChange({ searchTerm, selectedCategories });
  }, [searchTerm, selectedCategories, onFilterChange]);

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(cat => cat !== category) 
        : [...prev, category]
    );
  };

  return (
    <Card className="p-3">
      <Card.Body>
        <h5 className="mb-3">Filter Styles</h5>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            placeholder="Search styles..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>

        <h5 className="mb-3">Categories</h5>
        <Form>
          {categories.map(category => (
            <Form.Check 
              key={category}
              type="checkbox"
              id={`category-${category}`}
              label={category}
              value={category}
              checked={selectedCategories.includes(category)}
              onChange={() => handleCategoryChange(category)}
              className="mb-2"
            />
          ))}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Filters;
