// src/features/pages/Home.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { fetchStyles, fetchStylePriority } from '../services/api';
import StyleList from '../components/StyleList';
import Filters from '../components/Filters';
import PaginationComponent from '../components/PaginationComponent';
import { mergeStyles, getUniqueCategories } from '../services/utils';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { Offcanvas, Button } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight, FaFilter } from 'react-icons/fa'; // Import icons

const ITEMS_PER_PAGE = 30;
const CACHE_DURATION = 48 * 60 * 60 * 1000; // 48 hours

const Home = () => {
  const [styles, setStyles] = useState([]);
  const [filteredStyles, setFilteredStyles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showFilters, setShowFilters] = useState(false); // Offcanvas visibility
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // Sidebar collapse state

  // Set page title
  useEffect(() => {
    document.title = "Wescan Product Catalogue";
  }, []);

  // Fetch and cache styles using a custom hook
  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted component

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Retrieve cached data
        let rankedStyles = JSON.parse(localStorage.getItem('stylePriority'));
        const cacheTimestamp = localStorage.getItem('stylePriorityTimestamp');
        const now = Date.now();

        // If no cache or cache expired, fetch new data
        if (!rankedStyles || (cacheTimestamp && now - cacheTimestamp > CACHE_DURATION)) {
          const response = await fetchStylePriority();
          rankedStyles = response.data;
          localStorage.setItem('stylePriority', JSON.stringify(rankedStyles));
          localStorage.setItem('stylePriorityTimestamp', now);
        }

        // Fetch styles data
        const stylesResponse = await fetchStyles();
        const allStyles = mergeStyles(rankedStyles, stylesResponse.data).filter(style => {
          const styleCategories = Array.isArray(style.baseCategory) ? style.baseCategory : [style.baseCategory];
          return !styleCategories.includes('Office Use');
        });

        if (isMounted) {
          setStyles(allStyles);
          setFilteredStyles(allStyles);
          setCategories(getUniqueCategories(allStyles));
        }
      } catch (err) {
        console.error('Error fetching styles:', err);
        if (isMounted) {
          setError('Failed to load styles. Please try again later.');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false
    return () => {
      isMounted = false;
    };
  }, []);

  // Handle filter changes
  const handleFilterChange = useCallback(({ searchTerm, selectedCategories }) => {
    let filtered = styles;

    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(style =>
        (style.styleName && style.styleName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (style.brandName && style.brandName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (style.title && style.title.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (style.description && style.description.toLowerCase().includes(lowerCaseSearchTerm))
      );
    }

    if (selectedCategories.length > 0) {
      filtered = filtered.filter(style => {
        const styleCategories = Array.isArray(style.baseCategory) ? style.baseCategory : [style.baseCategory];
        return styleCategories.some(cat => selectedCategories.includes(cat));
      });
    }

    setFilteredStyles(filtered);
    setCurrentPage(1); // Reset to first page when filters are applied
  }, [styles]);

  // Calculate total pages
  const totalPages = Math.ceil(filteredStyles.length / ITEMS_PER_PAGE);

  // Get current styles based on pagination
  const currentStyles = filteredStyles.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  // Handle page change
  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) {
      return; // Prevent invalid page numbers
    }
    setCurrentPage(page);
  };

  // Toggle sidebar collapse
  const toggleSidebar = () => {
    setIsSidebarCollapsed(prev => !prev);
  };

  return (
    <div className="container-fluid" style={{ paddingTop: '20px' }}>
      <div className="row">
        {/* Toggle Button for Small Screens */}
        <div className="col-12 d-md-none mb-3">
          <Button variant="primary" onClick={() => setShowFilters(true)}>
            <FaFilter /> Filters
          </Button>
        </div>

        {/* Left Pane Filters for Medium and Larger Screens */}
        <div
          className={
            !isSidebarCollapsed
              ? "col-lg-2 col-md-3 d-none d-md-block bg-light"
              : "d-none d-md-block"
          }
          id="left-pane"
        >
          {!isSidebarCollapsed && (
            <div className="d-flex flex-column h-100">
              <Filters categories={categories} onFilterChange={handleFilterChange} />
              <Button 
                variant="" 
                onClick={toggleSidebar} 
                className="mt-2 align-self-center"
                aria-label="Collapse Filters"
              >
                <FaChevronLeft /> Hide Filters
              </Button>
            </div>
          )}
        </div>

        {/* Main Content Area */}
        <div
          className={
            !isSidebarCollapsed
              ? "col-lg-10 col-md-9"
              : "col-lg-12 col-md-12"
          }
          id="main-content"
        >
          {/* Show expand button when sidebar is collapsed */}
          {isSidebarCollapsed && (
            <Button 
              variant="secondary" 
              onClick={toggleSidebar} 
              className="mb-3 d-none d-md-block"
              aria-label="Expand Filters"
            >
              <FaChevronRight /> Filters
            </Button>
          )}

          {loading && <LoadingIndicator />}

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {!loading && !error && (
            <>
              <StyleList styles={currentStyles} />
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </div>

      {/* Offcanvas for Filters on Small Screens */}
      <Offcanvas show={showFilters} onHide={() => setShowFilters(false)} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Filters categories={categories} onFilterChange={handleFilterChange} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Home;
