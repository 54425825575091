// src/App.jsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import AppNavbar from './components/Navbar';
import AppRoutes from './routes';
import Home from './features/ProductCatalogue/pages/Home';
import Product from './features/ProductCatalogue/pages/Product';

// Initialize GA4
ReactGA.initialize('G-DMNX35916H');

// Custom hook for page views
const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
};

const App = () => {
  return (
    <Router>
      <AppNavbar />
      <AnalyticsTracker />
      <AppRoutes />
    </Router>
  );
};

const AnalyticsTracker = () => {
  usePageViews();
  return null;
};

export default App;
