// src/components/LoadingIndicator.jsx

import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

const messages = [
  "Fluffing out new styles, one second!",
  "Ironing out the wrinkles… Hang tight!",
  "Sorting sizes… Style is on its way!",
  "Mixing fabrics and magic…",
  "Stitching together something fabulous!",
  "Turning threads into trends!",
  "Designing your next favorite outfit!",
  "Brewing the perfect fit!",
  "Crafting styles you'll love!",
  "Sewing up the best deals!",
  "Weaving creativity into every thread!",
  "Tailoring styles just for you!"
];

const LoadingIndicator = () => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setMessage(messages[randomIndex]);
    }, []);

    return (
        <div className="d-flex flex-column align-items-center justify-content-center my-4">
            <Spinner animation="border" role="status" className="mb-3" variant="primary"> {/* Use Bootstrap variant */}
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>{message}</div>
        </div>
    );
};

export default LoadingIndicator;