// src/pages/AboutUs.jsx
import React from 'react';

const AboutUs = () => (
  <div className="container my-4">
    <h1>About Us</h1>
    <p>Welcome to our company...</p>
  </div>
);

export default AboutUs;
