// src/services/api.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://wesc-proxy-server-b9274b7cef78.herokuapp.com';

export const fetchStyles = () => axios.get(`${API_BASE_URL}/styles/`);

export const fetchStylePriority = () => axios.get(`${API_BASE_URL}/stylePriority`);

export const fetchStyleDetails = (styleID) => axios.get(`${API_BASE_URL}/styles/${styleID}`);

export const fetchProducts = (styleID) => axios.get(`${API_BASE_URL}/products/?style=${styleID}`);
