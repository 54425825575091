// src/routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './features/ProductCatalogue/pages/Home';
import Product from './features/ProductCatalogue/pages/Product';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import TermsOfService from './pages/TermsOfService';
import NotFound from './components/NotFound';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/product/:styleID" element={<Product />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/terms-of-service" element={<TermsOfService />} />
    {/* Explicit NotFound Route */}
    <Route path="/not-found" element={<NotFound />} />
    {/* Catch-all route for undefined paths */}
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
