// src/components/ProductProofImagesComponent.jsx

import React, { useEffect, useState } from 'react';
import { Spinner, Alert, Toast, ToastContainer } from 'react-bootstrap';
import { processImage } from '../services/imageProcessor';
import './ProductProofImagesComponent.css'; // Import the CSS file for styling

const ProductProofImagesComponent = ({ imageUrls }) => {
    const [processedImages, setProcessedImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [copyToastVisible, setCopyToastVisible] = useState(false);
    const [copyToastMessage, setCopyToastMessage] = useState('');

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        setError(null);
        setProcessedImages([]);

        const processAllImages = async () => {
            try {
                const promises = imageUrls.map(imgObj => processImage(imgObj.url));
                const results = await Promise.all(promises);
                if (isMounted) {
                    setProcessedImages(results);
                    setLoading(false);
                }
            } catch (err) {
                console.error('Error processing images:', err);
                if (isMounted) {
                    setError('Failed to process images.');
                    setLoading(false);
                }
            }
        };

        if (imageUrls.length > 0) {
            processAllImages();
        } else {
            setLoading(false);
        }

        return () => {
            isMounted = false;
        };
    }, [imageUrls]);

    const handleImageClick = async (src) => {
        try {
            // Process the image to get a PNG data URL
            const pngDataUrl = await processImage(src);
    
            // Convert the data URL to a Blob
            const response = await fetch(pngDataUrl);
            const blob = await response.blob();
    
            // Create a ClipboardItem with the PNG blob
            const clipboardItem = new ClipboardItem({ 'image/png': blob });
    
            // Write the ClipboardItem to the clipboard
            await navigator.clipboard.write([clipboardItem]);
    
            // Update UI to indicate success
            setCopyToastMessage('Image copied to clipboard!');
            setCopyToastVisible(true);
        } catch (err) {
            console.error('Failed to copy image:', err);
            // Update UI to indicate failure
            setCopyToastMessage('Failed to copy image.');
            setCopyToastVisible(true);
        }
    };
    

    if (loading) {
        return (
            <div className="d-flex justify-content-center my-4">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading proof images...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return (
            <Alert variant="danger" className="my-4">
                {error}
            </Alert>
        );
    }

    return (
        <div id="productProofImages" className="my-4 proof-images-wrapper">
            <h5>Proof Images</h5>
            <p><span class="text-uppercase badge text-bg-primary">CTRL ALT P</span> Click to copy image.</p>
            <div className="proof-images-container">
                {processedImages.map((src, index) => (
                    <img
                        key={index}
                        src={src}
                        alt={`Proof ${index + 1}`}
                        className="img-fluid proof-image mb-3"
                        onClick={() => handleImageClick(src)}
                        style={{ cursor: 'pointer' }}
                    />
                ))}
            </div>
            {/* Toast for copy notification */}
            <ToastContainer position="bottom-center" className="p-3">
                <Toast
                    onClose={() => setCopyToastVisible(false)}
                    show={copyToastVisible}
                    delay={2000}
                    autohide
                    bg="success"
                >
                    <Toast.Body style={{ color: 'white' }}>{copyToastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default ProductProofImagesComponent;
