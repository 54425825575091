// src/components/NotFound.jsx

import React, { useMemo } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './NotFound.css'; // Custom CSS for styling

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigate back to Home page
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const query = e.target.value.trim();
      if (query) {
        navigate(`/search?q=${encodeURIComponent(query)}`);
      }
    }
  };

  // Array of fun 404 messages
  const messages = [
    "Looks like you're lost in cyberspace!",
    "Whoops! This page took a wrong turn.",
    "404: This page decided to hide.",
    "Uh-oh! The page you're looking for vanished.",
    "Houston, we have a 404 problem.",
    "This page is playing hide and seek!",
    "Oops! We can't find that page.",
    "Well, this is embarrassing...",
    "The page you're looking for is on vacation.",
    "Error 404: Page not found. But hey, take a break!",
  ];

  // Select a random message using useMemo to ensure it's selected once per render
  const randomMessage = useMemo(() => {
    return messages[Math.floor(Math.random() * messages.length)];
  }, [messages]);

  return (
    <Container className="not-found-container text-center my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          {/* Playful Message */}
          <h1 className="display-4">Oops! Page Not Found</h1>
          <p className="lead">
            {randomMessage}
          </p>

          <p className="lead">
            If this is unexpected, please contact the Wescan team as we would love to fix this bug
          </p>
          {/* Call-to-Action Buttons */}
          <Button variant="primary" size="lg" onClick={handleGoHome} className="mx-2">
            <span role="img" aria-label="home">
              🏠
            </span>{' '}
            Go Back Home
          </Button>


        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
