// src/components/Navbar.jsx
import React, { useState } from 'react';
import { Navbar, Nav, Container, Button, Modal } from 'react-bootstrap';
import { FiExternalLink } from 'react-icons/fi'; // Importing an icon
import './Navbar.css'; // Import the custom CSS
import logo_pms_reverse from '../../src/assets/images/Wescan_H_PMS_Reverse.svg'

const AppNavbar = () => {
  // State to control the visibility of the Info modal
  const [showInfo, setShowInfo] = useState(false);

  // Handlers to open and close the Info modal
  const handleInfoShow = () => setShowInfo(true);
  const handleInfoClose = () => setShowInfo(false);

  return (
    <>
      <Navbar expand="lg" className="custom-navbar" variant="dark">
        {/* Full-width Container */}
        <Container fluid className="px-4">
          {/* Brand Logo */}
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <img
              src={logo_pms_reverse}
              alt="Logo"
              className="navbar-logo"
            />

          </Navbar.Brand>
          
          {/* Toggle for mobile view */}
          <Navbar.Toggle aria-controls="navbar-nav" />

          {/* Navigation Links and Actions */}
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto align-items-center">
              {/* Catalogue Link */}
              <Nav.Link href="/" className="nav-link-custom">
                Catalogue
              </Nav.Link>

              {/* Quick Quote Link (Hidden for now) */}
              <Nav.Link href="/quick-quote" className="nav-link-custom d-none">
                Quick Quote
              </Nav.Link>

              {/* Info Link */}
              <Nav.Link onClick={handleInfoShow} className="nav-link-custom" href="#">
                Info
              </Nav.Link>

              {/* Exit Catalogue Button */}
              <Button 
                className="btn-orange px-3 py-2 d-flex align-items-center ms-3"
                href="https://wescanedmonton.com" 
              >
                Exit Catalogue <FiExternalLink className="ms-2" />
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Info Modal */}
      <Modal show={showInfo} onHide={handleInfoClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Website Under Development</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This website is currently under development and is being continually improved to enhance our customers' experience. If you experience any issues or bugs, please be sure to let our team know at hello@wescanedmonton.com.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleInfoClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AppNavbar;
