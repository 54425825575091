// src/components/InventoryTable.jsx

import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  Table,
  Badge,
  Alert,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  FaShoppingCart,
  FaBoxOpen,
  FaWarehouse,
  FaTruck,
  FaShippingFast,
  FaHandshake,
} from 'react-icons/fa'; // Importing Font Awesome Icons

const InventoryTable = ({ products = [], selectedColor }) => {
  // -------------------------------
  // 1. State Hooks at the Top Level
  // -------------------------------
  const [activeTab, setActiveTab] = useState('1+ Unit Pricing');

  // Early return if products array is invalid or empty
  if (!Array.isArray(products) || products.length === 0) {
    return null;
  }

  // Extract sizes and warehouses
  const warehouseOrder = ['AB', 'BC', 'ON'];

  const sizes = Array.from(new Set(products.map((p) => p.sizeName)));
  let warehouses = Array.from(
    new Set(products.flatMap((p) => p.warehouses.map((w) => w.warehouseAbbr)))
  ).filter((w) => w !== 'DS');

  // Sort warehouses based on the defined order
  warehouses.sort(
    (a, b) => warehouseOrder.indexOf(a) - warehouseOrder.indexOf(b)
  );

  // Utility Functions for Dates
  const isBusinessDay = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Sunday = 0, Saturday = 6
  };

  const addBusinessDays = (date, days) => {
    let result = new Date(date);
    let addedDays = 0;
    while (addedDays < days) {
      result.setDate(result.getDate() + 1);
      if (isBusinessDay(result)) {
        addedDays += 1;
      }
    }
    return result;
  };

  const getNextTueOrThu = (date) => {
    const day = date.getDay();
    let daysToAdd = 0;
    if (day === 2 || day === 4) {
      // Tuesday or Thursday
      daysToAdd = 0;
    } else if (day < 2) {
      // Sunday or Monday
      daysToAdd = 2 - day;
    } else if (day < 4) {
      // Wednesday
      daysToAdd = 4 - day;
    } else {
      // Friday or Saturday
      daysToAdd = 9 - day; // Next Tuesday
    }
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + daysToAdd);
    return nextDate;
  };

  const getExpectedArrivalDate = (warehouse) => {
    let daysToAdd;

    switch (warehouse) {
      case 'AB':
        daysToAdd = 2;
        break;
      case 'BC':
        daysToAdd = 3;
        break;
      case 'ON':
        daysToAdd = 5;
        break;
      default:
        daysToAdd = 5;
    }

    // Get current date and time in MST
    const now = new Date();
    const mstOffset = -7; // MST is UTC-7
    const utc = now.getTime() + now.getTimezoneOffset() * 60000;
    const mstTime = new Date(utc + mstOffset * 3600000);

    // Determine if today should be included
    let startDate = new Date(mstTime);
    if (mstTime.getHours() >= 12) {
      startDate.setDate(startDate.getDate() + 1);
    }

    // Find the next Tuesday or Thursday from startDate
    const nextTueOrThu = getNextTueOrThu(startDate);

    // Add daysToAdd business days from nextTueOrThu
    const arrivalDate = addBusinessDays(nextTueOrThu, daysToAdd);

    const options = {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };
    return arrivalDate.toLocaleDateString('en-US', options);
  };

  // Function to Get Price by Size and Tier
  const getPriceBySize = (sizeName, tier) => {
    const product = products.find((p) => p.sizeName === sizeName);
    return product ? product[tier] : null;
  };

  // Retrieve Color Information
  const colorProduct =
    products.find((p) => p.colorName === selectedColor) || {};
  const colorName = selectedColor || 'No Color Selected';
  const colorSwatchTextColor = colorProduct.colorSwatchTextColor || '#000';

  // Define Pricing Tiers with Icons
  const pricingTiers = [
    {
      title: '1+ Unit Pricing',
      tier: 'wescanPrice',
      icon: <FaShoppingCart />,
    },
    {
      title: '72+ Pricing',
      tier: 'wescan72',
      icon: <FaBoxOpen />,
    },
    {
      title: '144+ Pricing',
      tier: 'wescan144',
      icon: <FaWarehouse />,
    },
    {
      title: '288+ Pricing',
      tier: 'wescan288',
      icon: <FaTruck />,
    },
    {
      title: '576+ Pricing',
      tier: 'wescan576',
      icon: <FaShippingFast />,
    },
    {
      title: '864+ Pricing',
      tier: null, // Custom pricing
      icon: <FaHandshake />,
    },
  ];

  // Render the Component
  return (
    <div className="inventory-table-container">
      {/* Tabs and Table in a Single Container for Seamless Layout */}
      <div
        className="mb-0 bg-light rounded-top"
        style={{
          border: '1px solid #dee2e6',
          borderBottom: 'none',
          boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        }}
      >
        <Tabs
          id="pricing-tiers-tabs"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="nav-tabs"
          fill
          justify
          variant="tabs"
          mountOnEnter
          unmountOnExit
        >
          {pricingTiers.map(({ title, tier, icon }) => (
            <Tab
              eventKey={title}
              title={
                <span className="d-flex align-items-center">
                  {icon}
                  <span className="ms-2">{title}</span>
                </span>
              }
              key={title}
            >
              {/* Embed the content directly inside each Tab */}
              {tier ? (
                <Table
                  bordered
                  hover
                  striped
                  className="text-center align-middle mb-0"
                  style={{
                    boxShadow: '0 4px 8px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                    overflow: 'hidden',
                  }}
                >
                  <thead className="table-light">
                    <tr>
                      <th
                        rowSpan="2"
                        className="text-start align-middle"
                        style={{
                          width: '200px',
                          backgroundColor: '#f8f9fa',
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            width: '100px',
                            height: '50px',
                            marginBottom: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            background: colorProduct.color1
                              ? `linear-gradient(to right, ${
                                  colorProduct.color1 || '#ffffff'
                                } 50%, ${
                                  colorProduct.color2 ||
                                  colorProduct.color1 ||
                                  '#ffffff'
                                } 50%)`
                              : '#ffffff',
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              color: colorSwatchTextColor,
                              fontSize: '12px',
                              textAlign: 'center',
                              width: '100%',
                              padding: '2px',
                            }}
                          >
                            {colorName}
                          </span>
                        </div>
                        <strong>Warehouses</strong>
                      </th>
                      {sizes.map((size) => (
                        <th
                          key={size}
                          className="text-center"
                          style={{ backgroundColor: '#f1f3f5' }}
                        >
                          {size}
                        </th>
                      ))}
                    </tr>
                    <tr>
                      {sizes.map((size) => {
                        const price = getPriceBySize(size, tier);
                        return (
                          <th
                            key={size}
                            className="text-center"
                            style={{ backgroundColor: '#f1f3f5' }}
                          >
                            {price !== null && price !== undefined
                              ? `$${price.toFixed(2)}`
                              : 'N/A'}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {warehouses.map((warehouse) => (
                      <tr key={warehouse}>
                        <td className="text-start">
                          <strong>{warehouse}</strong>
                          <br />
                          <small>
                            Arrives on {getExpectedArrivalDate(warehouse)}
                          </small>
                        </td>
                        {sizes.map((size) => {
                          const product = products.find(
                            (p) =>
                              p.sizeName === size &&
                              p.warehouses.some(
                                (w) => w.warehouseAbbr === warehouse
                              )
                          );
                          const warehouseData = product?.warehouses.find(
                            (w) => w.warehouseAbbr === warehouse
                          );
                          const qty = warehouseData?.qty || 0;
                          const expectedInventory =
                            warehouseData?.expectedInventory;
                          const isCloseout = warehouseData?.closeout || false;

                          return (
                            <td key={size}>
                              <OverlayTrigger
                                trigger="click" // Change trigger to 'click'
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id={`tooltip-${warehouse}-${size}`}
                                    style={{
                                      maxWidth: '200px', // Increase tooltip width
                                    }}
                                  >
                                    Expected Inventory:{' '}
                                    {expectedInventory || 'N/A'}
                                  </Tooltip>
                                }
                                rootClose // Enable rootClose to hide tooltip when clicking outside
                              >
                                <span style={{ cursor: 'pointer' }}>{qty}</span>
                              </OverlayTrigger>
                              {isCloseout && (
                                <Badge bg="danger" className="ms-2">
                                  DISCONTINUED
                                </Badge>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: '200px' }}
                >
                  <Badge bg="warning" text="dark" className="fs-5">
                    Contact for custom pricing
                  </Badge>
                </div>
              )}
            </Tab>
          ))}
        </Tabs>
      </div>

      {/* Bottom Information Section */}
      <div className="mt-4">
        {/* Decoration Information */}
        <Alert variant="info">
          <strong>Bulk discounts</strong> are based on the total quantity of this
          specific garment, regardless of the size. For example, if you order 100
          smalls, 100 mediums, and 100 larges, your order will qualify for pricing
          based on 300 units.

          <br />
          <br />
          <strong>Decoration price not included.</strong> Pricing displayed here
          does not include decoration (printing/embroidery), as the decoration is
          dependent on your design requirements.

          <br />
          <br />

          <strong>Dates shown</strong> are the estimated arrival date of the
          garments into our production facility. We will require additional time to
          print/embroider your custom design onto the garment.

          <br />
          <br />
          <strong>To place an order,</strong> please{' '}
          <a
            href="http://wescanedmonton.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>{' '}
        </Alert>
      </div>
    </div>
  );
};

// Define PropTypes for better type checking
InventoryTable.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      sizeName: PropTypes.string.isRequired,
      wescanPrice: PropTypes.number,
      wescan72: PropTypes.number,
      wescan144: PropTypes.number,
      wescan288: PropTypes.number,
      wescan576: PropTypes.number,
      wescan864: PropTypes.number,
      colorName: PropTypes.string,
      color1: PropTypes.string,
      color2: PropTypes.string,
      colorSwatchTextColor: PropTypes.string,
      warehouses: PropTypes.arrayOf(
        PropTypes.shape({
          warehouseAbbr: PropTypes.string.isRequired,
          qty: PropTypes.number,
          expectedInventory: PropTypes.string,
          closeout: PropTypes.bool,
        })
      ).isRequired,
    })
  ).isRequired,
  selectedColor: PropTypes.string,
};

export default InventoryTable;
