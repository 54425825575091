// src/features/ProductCatalogue/pages/Product.jsx

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Toast,
  ToastContainer,
} from 'react-bootstrap';

import CarouselComponent from '../components/Carousel';
import ColorSwatches from '../components/ColorSwatches';
import InventoryTable from '../components/InventoryTable';
import ProductProofImagesComponent from '../components/ProductProofImagesComponent';
import LoadingIndicator from '../../../components/LoadingIndicator';

const IMAGE_FIELDS = [
  'colorFrontImage',
  'colorSideImage',
  'colorBackImage',
  'colorDirectSideImage',
  'colorOnModelFrontImage',
  'colorOnModelSideImage',
  'colorOnModelBackImage',
];

const Product = () => {
  const { styleID } = useParams();
  const navigate = useNavigate();

  const [styleData, setStyleData] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showProofImages, setShowProofImages] = useState(false);
  const [proofImageUrls, setProofImageUrls] = useState([]);
  const [toast, setToast] = useState({
    visible: false,
    message: '',
  });

  // Fetch style and product data
  const fetchData = useCallback(async () => {
    try {
      const [styleResponse, productsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/styles/${styleID}`),
        axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/products/?style=${styleID}`
        ),
      ]);

      if (styleResponse.data.length === 0) {
        // If no style data found, navigate to NotFound
        navigate('/not-found', { replace: true });
        return;
      }

      setStyleData(styleResponse.data[0]);
      setProducts(productsResponse.data);
    } catch (error) {
      console.error('Error fetching product details:', error);

      if (error.response && error.response.status === 404) {
        // If 404 error, navigate to NotFound
        navigate('/not-found', { replace: true });
      } else {
        // For other errors, show toast notification
        setToast({
          visible: true,
          message: 'Failed to load product details. Please try again later.',
        });
      }
    }
  }, [styleID, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Update document title
  useEffect(() => {
    if (styleData) {
      document.title = `${styleData.brandName} ${styleData.title} - ${styleData.styleName}`;
    }
  }, [styleData]);

  // Filter products based on selected color
  useEffect(() => {
    if (selectedColor) {
      const filtered = products.filter(
        (product) => product.colorName === selectedColor
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [selectedColor, products]);

  // Generate unique colors
  const uniqueColors = useMemo(() => {
    return [...new Map(products.map((item) => [item.colorName, item])).values()];
  }, [products]);

  // Get Proof image URLs (excluding styleImage)
  const getProofImageUrls = useCallback(() => {
    if (!selectedColor) return [];

    const colorProducts = products.filter(
      (product) => product.colorName === selectedColor
    );

    const imageUrls = colorProducts.flatMap((product) =>
      IMAGE_FIELDS
        .map((field) => product[field])
        .filter(Boolean)
        .map((url) => `https://www.ssactivewear.com/${url}`)
    );

    // Remove duplicates
    return Array.from(new Set(imageUrls)).map((url) => ({ url }));
  }, [selectedColor, products]);

  // Handle color selection
  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  // Navigate back to Home page
  const handleBackClick = () => {
    navigate('/'); // Navigate back to Home page
  };

  // Toggle Proof Images visibility
  const toggleProofImages = useCallback(() => {
    if (showProofImages) {
      setShowProofImages(false);
      setProofImageUrls([]);
      setToast({
        visible: true,
        message: 'Product Proof Images Hidden (Ctrl+Alt+P)',
      });
    } else {
      const imageUrls = getProofImageUrls();
      setProofImageUrls(imageUrls);
      setShowProofImages(true);
      setToast({
        visible: true,
        message: 'Loading Product Proof Images (Ctrl+Alt+P)',
      });
    }
  }, [showProofImages, getProofImageUrls]);

  // Update proofImageUrls when selectedColor changes and showProofImages is true
  useEffect(() => {
    if (showProofImages) {
      const imageUrls = getProofImageUrls();
      setProofImageUrls(imageUrls);
    }
  }, [selectedColor, showProofImages, getProofImageUrls]);

  // Keyboard shortcut handler for Control+Alt+P
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.altKey && e.key.toLowerCase() === 'p') {
        e.preventDefault();
        toggleProofImages();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [toggleProofImages]);

  if (!styleData) {
    return <LoadingIndicator />;
  }

  return (
    <Container className="my-4">
      {/* Back Button */}
      <Button variant="secondary" onClick={handleBackClick} className="mb-3">
        &larr; Back to All Styles
      </Button>

      <Row>
        <Col md={4}>
          <CarouselComponent
            styleData={styleData}
            products={filteredProducts}
            selectedColor={selectedColor}
          />
        </Col>

        <Col md={8}>
          <h2>{`${styleData.brandName} ${styleData.title} - ${styleData.styleName}`}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: styleData.description }}
          ></div>
        </Col>
      </Row>

      {uniqueColors.length > 0 && (
        <Row className="my-4">
          <Col>
            <Form.Group className="my-3" controlId="colorDropdown">
              <Form.Label>
                <strong>Select Color:</strong>
              </Form.Label>
              <Form.Select
                value={selectedColor}
                onChange={(e) => handleColorChange(e.target.value)}
                style={{ maxWidth: '300px' }}
              >
                <option value="">-- Select a Color --</option>
                {uniqueColors.map((product) => (
                  <option key={product.colorName} value={product.colorName}>
                    {product.colorName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <ColorSwatches
              colors={uniqueColors}
              selectedColor={selectedColor}
              onColorSelect={handleColorChange}
            />
          </Col>
        </Row>
      )}

      {/* Conditionally render ProductProofImagesComponent above InventoryTable */}
      {showProofImages && proofImageUrls.length > 0 && (
        <Row>
          <Col>
            <ProductProofImagesComponent imageUrls={proofImageUrls} />
          </Col>
        </Row>
      )}

      {selectedColor && (
        <Row>
          <Col>
            <InventoryTable
              products={filteredProducts}
              selectedColor={selectedColor}
            />
          </Col>
        </Row>
      )}

      {/* Toast for visual indicator */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setToast((prev) => ({ ...prev, visible: false }))}
          show={toast.visible}
          delay={3000}
          autohide
          bg="light"
        >
          <Toast.Header
            style={{ backgroundColor: '#E57200', color: 'white' }}
          >
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body style={{ backgroundColor: 'white', color: 'black' }}>
            {toast.message}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
};


export default Product;
