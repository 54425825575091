// src/components/StyleList.jsx
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import StyleCard from './StyleCard';

const StyleList = ({ styles }) => {
  return (
    <Row>
      {styles.map(style => (
        <Col key={style.styleID} xs={6} sm={4} md={3} lg={2} className="mb-4">
          <StyleCard style={style} />
        </Col>
      ))}
    </Row>
  );
};

export default StyleList;
