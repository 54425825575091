// src/components/StyleCard.jsx
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const StyleCard = ({ style }) => {
  const availabilityIcon = getAvailabilityIcon(style.totalQty);
  // const updatedStyleImage = style.styleImage.replace('_fm', '_fs');
  const updatedStyleImage = style.styleImage;

  return (
    <Card 
      className="h-100" 
      style={{ 
        transition: 'transform 0.2s', 
        cursor: 'pointer' 
      }}
      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.04)'}
      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
    >
      <Link to={`/product/${style.styleID}`} className="text-decoration-none text-dark">
        <Card.Img 
          variant="top" 
          src={`https://www.ssactivewear.com/${updatedStyleImage}`} 
          alt={style.styleName} 
          loading="lazy" 
        />
        <Card.Body className="d-flex flex-column">
          <Card.Title>{style.styleName}</Card.Title>
          <Card.Text><strong>Brand:</strong> {style.brandName}</Card.Text>
          <Card.Text>{style.title}</Card.Text>
          <Card.Text className="small text-uppercase">Availability: {availabilityIcon}</Card.Text>
        </Card.Body>
      </Link>
    </Card>
  );
};

const getAvailabilityIcon = (totalQty) => {
  if (totalQty < 100) {
    return <span className="text-danger" title={`Approximate Stock: ${totalQty}`}>❌</span>;
  } else if (totalQty >= 100 && totalQty <= 999) {
    return <span className="text-warning" title={`Approximate Stock: ${totalQty}`}>🟡</span>;
  } else {
    return <span className="text-success" title={`Approximate Stock: ${totalQty}`}>✅</span>;
  }
};

export default StyleCard;
