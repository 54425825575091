// src/components/Carousel.jsx

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper/modules'; // Correct import for Navigation and Thumbs
import 'swiper/css';
import 'swiper/css/navigation'; // Import Swiper styles
import 'swiper/css/thumbs';

const CarouselComponent = ({ styleData, products, selectedColor }) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  const getImageUrls = () => {
    let imageUrls = [];

    if (selectedColor) {
      const colorProducts = products.filter(p => p.colorName === selectedColor);
      const imageFields = [
        'colorFrontImage',
        'colorSideImage',
        'colorBackImage',
        'colorDirectSideImage',
        'colorOnModelFrontImage',
        'colorOnModelSideImage',
        'colorOnModelBackImage',
      ];

      colorProducts.forEach(product => {
        imageFields.forEach(field => {
          const imageUrl = product[field];
          if (imageUrl) {
            const fullUrl = `https://www.ssactivewear.com/${imageUrl}`;
            if (!imageUrls.includes(fullUrl)) {
              imageUrls.push(fullUrl);
            }
          }
        });
      });

      if (styleData.styleImage) {
        const styleImageUrl = `https://www.ssactivewear.com/${styleData.styleImage}`;
        if (!imageUrls.includes(styleImageUrl)) {
          imageUrls.push(styleImageUrl);
        }
      }
    } else {
      if (styleData.styleImage) {
        imageUrls.push(`https://www.ssactivewear.com/${styleData.styleImage}`);
      }
    }

    return imageUrls;
  };

  const imageUrls = getImageUrls();

  return (
    <>
      <Swiper
        modules={[Navigation, Thumbs]} // Add modules here
        navigation
        thumbs={{ swiper: thumbsSwiper }}
        spaceBetween={10}
        slidesPerView={1}
        style={{ height: '400px' }}
      >
        {imageUrls.length > 0 ? (
          imageUrls.map((url, index) => (
            <SwiperSlide key={index}>
              <img src={url} alt={`Slide ${index + 1}`} className="img-fluid" style={{ height: '100%', objectFit: 'contain' }} />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <p>No images available.</p>
          </SwiperSlide>
        )}
      </Swiper>

      <Swiper
        modules={[Thumbs]} // Add thumbs module here
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode
        watchSlidesProgress
        style={{ height: '80px', marginTop: '10px' }}
      >
        {imageUrls.length > 0 ? (
          imageUrls.map((url, index) => (
            <SwiperSlide key={index}>
              <img src={url} alt={`Thumbnail ${index + 1}`} className="img-fluid" style={{ height: '100%', objectFit: 'contain', cursor: 'pointer' }} />
            </SwiperSlide>
          ))
        ) : null}
      </Swiper>
    </>
  );
};

export default CarouselComponent;
