// src/services/imageProcessor.js

export const processImage = (imgSrc) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // To avoid CORS issues
        img.src = imgSrc;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Desired height in inches and DPI
            const desiredHeightInches = 11;
            const dpi = 72; // Changed from 96 to 72
            const desiredHeightPx = desiredHeightInches * dpi;

            const scaleFactor = desiredHeightPx / img.height;
            const width = img.width * scaleFactor;
            const height = desiredHeightPx;

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            const text = 'For reference only, may not be to scale.';
            ctx.font = '12px Arial';
            ctx.fillStyle = 'gray';
            ctx.textAlign = 'center';
            ctx.fillText(text, width / 2, height - 10);

            // Change from 'image/jpeg' to 'image/png'
            resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = (error) => reject(error);
    });
};
