// src/utils.js

// Merges ranked styles with detailed styles based on the styleID
export function mergeStyles(rankedStyles, detailedStyles) {
    return rankedStyles.map(rankedStyle => {
      const detailedStyle = detailedStyles.find(style => style.styleID === rankedStyle.styleID);
      return {
        ...rankedStyle,
        ...detailedStyle, // This includes fields like title, description, etc.
      };
    });
  }
  
  // Extracts unique categories from the styles
  export function getUniqueCategories(styles) {
    const categoriesSet = new Set();
    styles.forEach(style => {
      const styleCategories = Array.isArray(style.baseCategory) ? style.baseCategory : [style.baseCategory];
      styleCategories.forEach(category => {
        if (category) {
          categoriesSet.add(category);
        }
      });
    });
    return Array.from(categoriesSet).sort();
  }
  