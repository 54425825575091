
import React from 'react';

const ColorSwatches = ({ colors, selectedColor, onColorSelect }) => {
  return (
    <div className="d-flex flex-wrap justify-content-start">
      {colors.map(color => (
        <div 
          key={color.colorName} 
          className={`color-swatch m-2 ${selectedColor === color.colorName ? 'selected-swatch' : ''}`} 
          style={{ 
            width: '64px', // Increased width
            height: '64px', // Increased height
            cursor: 'pointer', 
            position: 'relative',
            border: selectedColor === color.colorName ? '2px solid #007bff' : '1px solid #ccc',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px' // Increased font size
          }}
          onClick={() => onColorSelect(color.colorName)}
          title={color.colorName}
        >
          {color.colorSwatchImage ? (
            <>
              <img 
                src={`https://www.ssactivewear.com/${color.colorSwatchImage}`} 
                alt={color.colorName} 
                className="img-fluid" 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
              />
              <span 
                className="swatch-text" 
                style={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  width: '100%', 
                  height: '100%', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  fontSize: '12px', // Increased font size
                  color: color.colorSwatchTextColor || '#000',
                  textAlign: 'center',
                  padding: '2px' // Added padding for longer text
                }}
              >
                {color.colorName}
              </span>
            </>
          ) : (
            <>
              <div 
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  background: `linear-gradient(to right, ${color.color1 || '#ffffff'} 50%, ${color.color2 || color.color1 || '#ffffff'} 50%)` 
                }} 
              />
              <span 
                className="swatch-text" 
                style={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  width: '100%', 
                  height: '100%', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  fontSize: '12px', // Increased font size
                  color: color.colorSwatchTextColor || '#000',
                  textAlign: 'center',
                  padding: '2px' // Added padding for longer text
                }}
              >
                {color.colorName}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ColorSwatches;
